import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Services = () => (
  <StaticQuery
    query={graphql`
      query ServicePanels {
        wordpressAcfOptions(wordpress_id: { eq: "acf" }) {
          options {
            panel_1 {
              panel_1_title
              panel_1_url
              panel_1_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 460) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            panel_2 {
              panel_2_title
              panel_2_url
              panel_2_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 460) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            panel_3 {
              panel_3_title
              panel_3_url
              panel_3_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 460) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <section className="services">
          <Link
            to={`${data.wordpressAcfOptions.options.panel_1.panel_1_url}`}
            className="service"
            title={`${data.wordpressAcfOptions.options.panel_1.panel_1_title}`}
          >
            <Img
              alt="Service Image"
              fluid={
                data.wordpressAcfOptions.options.panel_1.panel_1_image.localFile
                  .childImageSharp.fluid
              }
            />
            <h3>{data.wordpressAcfOptions.options.panel_1.panel_1_title}</h3>
          </Link>
          <Link
            to={`${data.wordpressAcfOptions.options.panel_2.panel_2_url}`}
            className="service"
            title={`${data.wordpressAcfOptions.options.panel_2.panel_2_title}`}
          >
            <Img
              alt="Service Image"
              fluid={
                data.wordpressAcfOptions.options.panel_2.panel_2_image.localFile
                  .childImageSharp.fluid
              }
            />
            <h3>{data.wordpressAcfOptions.options.panel_2.panel_2_title}</h3>
          </Link>
          <Link
            to={`${data.wordpressAcfOptions.options.panel_3.panel_3_url}`}
            className="service"
            title={`${data.wordpressAcfOptions.options.panel_3.panel_3_title}`}
          >
            <Img
              alt="Service Image"
              fluid={
                data.wordpressAcfOptions.options.panel_3.panel_3_image.localFile
                  .childImageSharp.fluid
              }
            />
            <h3>{data.wordpressAcfOptions.options.panel_3.panel_3_title}</h3>
          </Link>
        </section>
      </>
    )}
  />
)

export default Services
