import { Link, StaticQuery, graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

const Logo = () => (
  <StaticQuery
    query={graphql`
      query PullLogo {
        file(relativePath: { regex: "/logo/" }) {
          childImageSharp {
            fluid(maxWidth: 132) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div className="logo">
          <Link
            to="/"
            style={{
              color: 'white',
              textDecoration: 'none',
            }}
          >
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="PB Creative Logo"
            />
          </Link>
        </div>
      </>
    )}
  />
)

export default Logo
