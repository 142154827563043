import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import Logo from '../components/logo'
import '../styles/header.scss'
import Menu from '../components/menu'

const Header = () => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        wordpressWpApiMenusMenusItems(slug: { eq: "main-nav" }) {
          items {
            title
            object_slug
            url
            type_label
            wordpress_children {
              title
              object_slug
              url
            }
          }
        }
        wordpressAcfOptions(wordpress_id: { eq: "acf" }) {
          options {
            contact_details {
              telephone_number
              email_address
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <header className="topbar">
          <div className="wrap">
            <Logo />
            <Menu
              menu={data.wordpressWpApiMenusMenusItems.items}
              contact={data.wordpressAcfOptions.options.contact_details}
              submenu={
                data.wordpressWpApiMenusMenusItems.items.wordpress_children
              }
            />
          </div>
        </header>
      </>
    )}
  />
)

export default Header
