import React, { Component } from 'react'
import Link from 'gatsby-link'

class Menu extends Component {
  state = { showMenu: false }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  render() {
    const menuActive = this.state.showMenu ? 'is-active' : ''
    const burgerActive = this.state.showMenu ? 'is-active' : ''
    const menu = this.props.menu
    const contact = this.props.contact
    const submenu = this.props.submenu
    return (
      <div>
        <nav className="group">
          <ul className="full_nav">
            {menu.map(item => {
              return item.type_label === 'Custom Link' ? (
                <li key={item.title} className={item.object_slug}>
                  <Link to={`/`} title={`${item.title}`}>
                    {item.title}
                  </Link>
                </li>
              ) : (
                <li key={item.title} className={item.object_slug}>
                  <Link to={`${item.url}`} title={`${item.title}`}>
                    {item.title}
                  </Link>
                  {item.wordpress_children !== null && (
                    <ul className="nav_sub">
                      {item.wordpress_children.map(subitem => (
                        <li key={subitem.title}>
                          <Link
                            to={`${subitem.url}`}
                            title={`${subitem.title}`}
                          >
                            {subitem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            })}
            <li className="menu_email">
              <a href={`mailto:${contact.email_address}`} title="Email Me">
                {contact.email_address}
              </a>
            </li>
            <li className="menu_call">
              <a href={`tel:${contact.telephone_number}`} title="Call Me">
                {contact.telephone_number}
              </a>
            </li>
          </ul>
          <ul className="mobile_nav">
            <li className="menu_email">
              <a href={`mailto:${contact.email_address}`} title="Email Me">
                <span>{contact.email_address}</span>
              </a>
            </li>
            <li className="menu_call">
              <a href={`tel:${contact.telephone_number}`} title="Call Me">
                <span>{contact.telephone_number}</span>
              </a>
            </li>
            <li
              className={`menu_toggle ${burgerActive}`}
              onClick={this.toggleMenu}
            >
              <a href="#" title="Menu">
                <span>.</span>
              </a>
            </li>
          </ul>
        </nav>
        <ul className={`mobile_menu ${menuActive}`}>
          {menu.map(item => {
            return item.type_label === 'Custom Link' ? (
              <li key={item.title}>
                <Link to={`/`} title={`${item.title}`}>
                  {item.title}
                </Link>
              </li>
            ) : (
              <li key={item.title}>
                <Link to={`${item.url}`} title={`${item.title}`}>
                  {item.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default Menu
