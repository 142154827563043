import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import '../styles/footer.scss'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query Footer {
        wordpressAcfOptions(wordpress_id: { eq: "acf" }) {
          options {
            enable_footer
            contact_details {
              telephone_number
              email_address
              personal_name
              site_name
            }
            contact_title
            contact_description
            button_text
            social_media {
              socials_in_footer
              twitter_url
              twitter_icon {
                localFile {
                  publicURL
                }
              }
              facebook_url
              facebook_icon {
                localFile {
                  publicURL
                }
              }
              instagram_url
              instagram_icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <footer>
          {data.wordpressAcfOptions.options.enable_footer === true && (
            <div className="strip">
              <div className="wrap">
                <div className="inner">
                  <h4>{data.wordpressAcfOptions.options.contact_title}</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.wordpressAcfOptions.options.contact_description,
                    }}
                  />
                </div>
                <Link to={`/contact`} title="Contact Me" className="button">
                  Contact Me
                </Link>
              </div>
            </div>
          )}
          <div className="wrap copyright">
            <div className="copy">
              <p>
                &copy;{' '}
                {data.wordpressAcfOptions.options.contact_details.site_name} |{' '}
                <a
                  href={`tel:${
                    data.wordpressAcfOptions.options.contact_details
                      .telephone_number
                  }`}
                  title="Call Me"
                >
                  {
                    data.wordpressAcfOptions.options.contact_details
                      .telephone_number
                  }
                </a>{' '}
                |{' '}
                <a
                  href={`mailto:${
                    data.wordpressAcfOptions.options.contact_details
                      .email_address
                  }`}
                >
                  {
                    data.wordpressAcfOptions.options.contact_details
                      .email_address
                  }
                </a>
              </p>
              <p>
                Unless otherwise stated, all imagery is copyright to PB Creative
                and cannot be used without written consent.
              </p>
            </div>
            {data.wordpressAcfOptions.options.social_media.socials_in_footer ===
              true && (
              <div className="socials">
                {data.wordpressAcfOptions.options.social_media.twitter_url && (
                  <a
                    href={
                      data.wordpressAcfOptions.options.social_media.twitter_url
                    }
                  >
                    <img
                      src={
                        data.wordpressAcfOptions.options.social_media
                          .twitter_icon.localFile.publicURL
                      }
                      alt="Twitter Icon"
                    />
                  </a>
                )}
                {data.wordpressAcfOptions.options.social_media.facebook_url && (
                  <a
                    href={
                      data.wordpressAcfOptions.options.social_media.facebook_url
                    }
                  >
                    <img
                      src={
                        data.wordpressAcfOptions.options.social_media
                          .facebook_icon.localFile.publicURL
                      }
                      alt="Facebook Icon"
                    />
                  </a>
                )}
                {data.wordpressAcfOptions.options.social_media
                  .instagram_url && (
                  <a
                    href={
                      data.wordpressAcfOptions.options.social_media
                        .instagram_url
                    }
                  >
                    <img
                      src={
                        data.wordpressAcfOptions.options.social_media
                          .instagram_icon.localFile.publicURL
                      }
                      alt="Instagram Icon"
                    />
                  </a>
                )}
              </div>
            )}
          </div>
        </footer>
      </>
    )}
  />
)

export default Footer
